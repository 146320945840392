"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

(function () {
  window.addEventListener('DOMContentLoaded', function (event) {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation'); // Loop over them and prevent submission

    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          form.querySelector('.btn-gradient .spinner').style.display = 'block';
          form.querySelector('.btn-gradient span').style.display = 'none';
        }

        form.classList.add('was-validated');
      }, false);
    });
    var inputs = document.querySelectorAll('.form-control.rounded-0');
    var checkboxes = document.querySelectorAll('.col-check input[type=checkbox]'); // handle totem cam

    var btnPhoto = document.querySelector('.btn-photo'),
        wrapperFields = document.querySelector('.wrapper-fields'),
        wrapperWebcam = document.querySelector('.wrapper-webcam'),
        btnSnap = document.querySelector('.btn-snap'),
        btnResume = document.querySelector('.btn-resume'),
        inputPhoto = document.querySelector('.photo');

    function resetWebcam() {
      webcam.stop();
      wrapperFields.classList.remove('d-none');
      wrapperWebcam.classList.add('d-none');
      wrapperWebcam.querySelector('.spinner').style.display = 'none';
      btnPhoto.querySelector('.arrow-left').classList.add('d-none');
      btnPhoto.querySelector('.photo-svg').classList.remove('d-none');
      btnPhoto.querySelector('span').innerHTML = 'or take a photo of your business card';
      btnSnap.style.display = 'none';
      canvasElement.classList.remove('d-block');
      canvasElement.classList.add('d-none');
      webcamElement.classList.remove('d-none');
      inputs.forEach(function (el) {
        el.value = '';
      });
      checkboxes.forEach(function (el) {
        el.checked = false;
      });
      document.getElementById('privacy').checked = false;
    } // handle modal close


    var modalFormButton = document.getElementById('modalForm');
    var config = {
      attributes: true,
      childList: false,
      subtree: false
    };

    function callback(mutationsList, observer) {
      var _iterator = _createForOfIteratorHelper(mutationsList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var mutation = _step.value;

          if (mutation.attributeName == 'class') {
            var cls = mutation.target.getAttribute('class');

            if (!cls.includes('show')) {
              resetWebcam();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    var observer = new MutationObserver(callback);
    observer.observe(modalFormButton, config);
    var webcamElement = document.getElementById('webcam');
    var canvasElement = document.getElementById('canvas');
    var snapSoundElement = document.getElementById('snapSound');
    var webcam = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);

    function beforeTakePhoto() {
      canvasElement.parentNode.classList.add('animate'); // setTimeout(function() {
      //     canvasElement.parentNode.classList.remove('animate');
      // }, 3000);
    }

    function afterTakePhoto() {
      canvasElement.classList.remove('d-none');
      canvasElement.classList.add('d-block');
      webcamElement.classList.add('d-none');
      btnSnap.style.display = 'none';
    } // take photo


    if (btnSnap) {
      btnSnap.addEventListener('click', function () {
        beforeTakePhoto();
        inputPhoto.value = webcam.snap();
        afterTakePhoto();
        webcam.stop();
        btnResume.style.display = 'block';
      });
    }

    if (btnResume) {
      btnResume.addEventListener('click', function () {
        canvasElement.classList.remove('d-block');
        canvasElement.classList.add('d-none');
        webcamElement.classList.remove('d-none');
        btnResume.style.display = 'none';
        canvasElement.parentNode.classList.remove('animate');
        wrapperWebcam.querySelector('.spinner').style.display = 'inline-block';
        webcam.start().then(function (result) {
          // console.log("webcam started");
          wrapperWebcam.querySelector('.spinner').style.display = 'none';
          btnSnap.style.display = 'block';
        }).catch(function (err) {// console.log(err);
        });
      });
    } // toggle show webcam or form fields


    if (btnPhoto) {
      btnPhoto.addEventListener('click', function (e) {
        e.preventDefault();
        wrapperFields.classList.toggle('d-none');
        wrapperWebcam.classList.toggle('d-none');
        wrapperWebcam.querySelector('.spinner').style.display = 'inline-block';
        this.querySelector('.arrow-left').classList.toggle('d-none');
        this.querySelector('.photo-svg').classList.toggle('d-none');
        inputs.forEach(function (el) {
          el.required = false;
          el.value = '';
        });
        checkboxes.forEach(function (el) {
          el.checked = false;
        });
        document.getElementById('privacy').checked = false;

        if (wrapperFields.classList.contains('d-none')) {
          this.querySelector('span').innerHTML = 'back';
          webcam.start().then(function (result) {
            // console.log("webcam started");
            wrapperWebcam.querySelector('.spinner').style.display = 'none';
            btnSnap.style.display = 'block';
          }).catch(function (err) {// console.log(err);
          });
          btnResume.style.display = 'none';
        } else {
          this.querySelector('span').innerHTML = 'or take a photo of your business card';
          inputs.forEach(function (el) {
            el.required = true;
          });
          canvasElement.classList.remove('d-block');
          canvasElement.classList.add('d-none');
          webcamElement.classList.remove('d-none');
          btnSnap.style.display = 'none';
          webcam.stop();
        }
      });
    }

    document.addEventListener('submit', function (e) {
      // Store reference to form to make later code easier to read
      var form = e.target; // get status message references

      var statusSuccess = form.querySelector('.status-success');
      var statusFailure = form.querySelector('.status-failure'); // Post data using the Fetch API

      var dataHeaders = {
        method: 'POST',
        body: JSON.stringify(Object.fromEntries(new FormData(form))),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        mode: 'cors',
        cache: 'default'
      };
      fetch(form.action, dataHeaders).then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        form.querySelector('.btn-gradient .spinner').style.display = 'none';
        form.querySelector('.btn-gradient span').style.display = 'block';
        document.getElementById('privacy').checked = false;
        checkboxes.forEach(function (el) {
          el.checked = false;
        });
        inputs.forEach(function (el) {
          el.value = '';
        });
        form.classList.remove("was-validated");

        if (data.status == 0) {
          statusFailure.hidden = false;
        } else {
          statusSuccess.hidden = false;
        }

        if (wrapperFields.classList.contains('d-none')) {
          resetWebcam();
        }

        setTimeout(function () {
          statusSuccess.hidden = true;
          statusFailure.hidden = true;
        }, 6000);
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      }); // Prevent the default form submit

      e.preventDefault();
    });
  }); //domcontentloaded
})();